/* eslint-disable import/first */

// Must import polyfills before jQuery to ensure they're available
import 'core-js/stable';

/* eslint no-global-assign: 0, no-new: 0, no-undef: 0, global-require: 0 */
import 'client/util/importJquery';
import 'regenerator-runtime/runtime';
import { Buffer } from 'buffer';
import { EventsPlugin } from '@jumpcloud/ui-events-vue';
import { FeatureFlagMixin } from 'client/store/modules/FeatureFlagsModule';
import { TestIdMixin } from '@jumpcloud-ap/test-id';
import { XhrConsts } from 'client/util/Constants';
import { buildUiEventsOptions } from '@jumpcloud-ap/ui-events-setup';
import { datadogLogs } from '@datadog/browser-logs';
import MspState from 'client/components/models/MspState';
import PortalVue from 'portal-vue';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import VueHotkey from 'v-hotkey';
import VueRouter from 'vue-router';
import VueScroll from 'vue-scroll';

import 'normalize.css';

import moment from 'moment';

import '../scss/bootstrap-reset.scss';
import '../scss/jumpcloud.scss';
import '@jumpcloud-ap/cart/css';
import '@jumpcloud-ap/feature-promos/css';
import '@jumpcloud-ap/feature-trials/css';
import '@jumpcloud-ap/time-based-trial/css';
import '@jumpcloud/ui-ar-payments/dist/style.css';
import '@jumpcloud/ui-payments/dist/style.css';

// Don't require jQuery if already defined as any registered plugins will be lost!
if (!window.jQuery && !window.$) {
  window.$ = $;
  window.jQuery = $;
}

window.moment = moment;
window.Buffer = Buffer;

const appVersion = process.env.VITE_VERSION || '0.0.0';
const appName = process.env.VITE_APP_NAME || 'local';
const isLocalViteInstance = process.env.NODE_ENV === 'development';

/**
 * Bootstrap is imported for a Bootstrap-vue view that just
 * assumes it is globally available.
 */
// eslint-disable-next-line import/no-relative-packages
import '../node_modules/bootstrap/dist/js/bootstrap';
import * as util from 'client/util/index';

const logError = (error) => {
  // get named logger specific to this MFE for version context
  const mfeLogger = datadogLogs.getLogger(appName);

  if (mfeLogger) {
    const { env } = mfeLogger.getContext();
    if (env !== 'local') {
      mfeLogger.error(`${appName} MFE Error: ${error.message}`, {}, error);
    }
  }
};

Vue.config.performance = process.env.NODE_ENV !== 'production';
Vue.config.errorHandler = (error, vm, info) => {
  // eslint-disable-next-line no-underscore-dangle
  const source = vm?._name;
  const type = info;
  const context = { source, type };

  if (!isLocalViteInstance) {
    window.DD_RUM.addError(error, context);
    logError(error);
  }

  // eslint-disable-next-line no-console
  console.error(error);
};

window.addEventListener('error', (event) => {
  if (!event.defaultPrevented
      && !isLocalViteInstance
      && event.error instanceof Error
      && event.error.stack?.includes('@jumpcloud-ap/monolith_app')) {
    logError(event.error);
  }
});

Vue.use(PortalVue);
Vue.use(VeeValidate, { errorBagName: 'v2Errors', fieldsBagName: 'v2Fields' });
Vue.use(VueHotkey);
Vue.use(VueRouter);
Vue.use(VueScroll);
Vue.mixin(FeatureFlagMixin);
Vue.mixin(TestIdMixin);
Vue.use(EventsPlugin, buildUiEventsOptions());

// For new Vue components

/**
 * Set orgId in the MspState if a multi-tenant admin user has navigated to
 * the admin portal to administer one of their managed organizations.
 */

// @todo This might not be necessary here because it's done elsewhere,
// but it doesn't hurt anything so I'm leaving it for now.
function setOrgId() {
  const orgId = util.getUrlParameter(XhrConsts.xOrgIdKey);

  if (orgId) {
    MspState.orgId = orgId;
  }
}

setOrgId();

// Initialize settings and navigate admin to either admin portal or MTP view

// move this to a lib that takes loggername, version
export const setupDatadogLogger = async () => {
  await fetch('/ui-settings.json').then(response => response.json()).then((data) => {
    const appEnv = data.ddAppEnvName || 'local';
    const sampleRate = parseInt(data.ddSampleRate, 10) || 100;

    // don't send DD logs from local
    if (appEnv !== 'local') {
      const clientToken = data.ddClientId || 'datadogfakekey';

      datadogLogs.init({
        allowUntrustedEvents: true,
        clientToken,
        site: 'datadoghq.com',
        forwardErrorsToLogs: false,
        sessionSampleRate: sampleRate,
        forwardConsoleLogs: [],
        env: appEnv,
        service: 'admin-portal',
      });

      // logger specific to this mfe with app context
      const mfeLogger = datadogLogs.createLogger(appName, {
        level: 'info',
        handler: 'http',
        context: {
          env: appEnv,
          version: appVersion,
          appName,
        },
      });

      // announce version to DD with info message on mount
      mfeLogger.info(`MFE: ${appName} version ${appVersion} mounted in ${appEnv}`);
    }
  });
};

let app;
export const init = async () => {
  const imports = await import('client/initialize/index');

  app = imports.app;
  await imports.initialize();
};

export const mountApp = async () => {
  const imports = await import('client/initialize/index');
  await imports.renderApp();
};

export const teardown = async () => {
  app.value?.$destroy();
  document.getElementById('single-spa-application:@jumpcloud-ap/monolith_app').innerHTML = '';
};
