export default {
  groupTypes: {
    deviceGroup: 'deviceGroup',
    policyGroup: 'policy_group',
    policyGroupTemplate: 'policy_group_template',
    systemGroup: 'system_group',
    userGroup: 'user_group',
  },
  memberTypes: {
    activeDirectory: 'active_directory',
    application: 'application',
    command: 'command',
    policy: 'policy',
    softwareApp: 'software_app',
    system: 'system',
    user: 'user',
    rule: 'rule',
  },
  operations: {
    add: 'add',
    remove: 'remove',
    update: 'update',
  },
};
